<template>
    <div class="container">
        <h1 class="mt-4">Besucherformular</h1>
        <hr>
        <p>
            Falls du die Schule einmal persönlich besuchen möchtest, kannst du dieses Formular ausfüllen.
            Wir planen deinen Besuch gemäß der aktuellen Schutzmaßnahmen und nehmen mit dir Kontakt auf.
        </p>
        <microsoft-form 
            :formid="formid"
            height="75em"/>
    </div>
</template>

<script>
import Formulare from "@/assets/json/formulare.json"
import MicrosoftForm from "@/components/MicrosoftForm"
export default {
    name: "Visit",
    components: { MicrosoftForm },
    data: () => ({
        formid: Formulare.besucher
    })
}
</script>