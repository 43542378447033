<template>
    <cookie-overlay :href="formUrl">
        <iframe
            ref="iframe"
            v-if="formid != ''"
            :sandbox="$root.allowCookies ? 'allow-same-origin allow-scripts allow-popups' : ''"
            :style="formStyle" 
            :src= "formSrc"
            frameborder= "0" 
            marginwidth= "0" 
            marginheight= "0"
            width="100%"
            height="100%"
            allowfullscreen 
            webkitallowfullscreen 
            mozallowfullscreen 
            msallowfullscreen
        />
    </cookie-overlay>
</template>
<script>
import CookieOverlay from '@/components/CookieOverlay';
export default {
    name: "MicrosoftFrom",
    components: { CookieOverlay },
    computed: {
        formUrl: function() {
            return `https://forms.office.com/Pages/ResponsePage.aspx?id=${this.formid}`;
        },
        formSrc: function() {
            return `https://forms.office.com/Pages/ResponsePage.aspx?id=${this.formid}&embed=true`;
        },
        formStyle: function() {
            return `width:${this.width};height:${this.height}`;
        }
    },
    props: {
        formid: String,
        width: {
            type: String,
            default: "100%"
        },
        height: {
            type: String,
            default: "90vh"
        },
    }
}
</script>